<template>
  <LayoutTunnel>
    <template slot="left-side">
      <div
        class="flex flex-col flex-1 items-start self-center justify-around pl-16 lg:px-8 max-w-xl xl:w-auto"
      >
        <div class="flex flex-col space-y-6 w-full">
          <div
            class="text-dark-grey-400 font-medium font-poppins text-3xl sm:text-2xl"
          >
            Les superficies et pièces
          </div>
          <div class="xl:w-3/4">
            <TextInput
              label="Quelle est la superficie totale de votre terrain ?"
              placeholder="superficie"
              rules="required"
              :value="form.informations.superficie_totale"
              @input="
                (val) => {
                  form.informations.superficie_totale = val
                }
              "
              type="number"
              unit="m²"
            />
          </div>
          <div class="xl:w-3/4">
            <TextInput
              label="Quelle est la surface construite du bien ?"
              placeholder="superficie"
              rules="required"
              :value="form.informations.superficie_construite"
              @input="
                (val) => {
                  form.informations.superficie_construite = val
                }
              "
              type="number"
              unit="m²"
            />
          </div>
          <div>
            <div class="mb-2 flex flex-col">
              <label class="text-dark-grey-400 font-poppins font-medium"
                >Combien y a-t-il de pièces ?
              </label>
              <span class="text-promy-gray-500 font-poppins"
                >hors cuisine, salle de bain et WC</span
              >
            </div>
            <div>
              <validation-provider
                class="relative flex flex-col"
                rules="required"
                v-slot="{ errors }"
              >
                <div
                  class="grid gap-2"
                  style="grid-template-columns: repeat(auto-fill, 110px)"
                >
                  <selectable-button
                    v-for="(piece, index) in pieces"
                    :key="piece + index"
                    v-model="form.informations.pieces"
                    :option-name="piece"
                  />
                </div>
                <span
                  v-show="errors[0]"
                  class="mt-1 text-xs text-promy-red-400"
                  >{{ errors[0] }}</span
                >
              </validation-provider>
            </div>
          </div>
          <div>
            <div class="mb-2 flex flex-col">
              <label class="text-dark-grey-400 font-poppins font-medium"
                >Quel est l’état de votre bien ?
              </label>
            </div>

            <div>
              <validation-provider
                class="relative flex flex-col"
                rules="required"
                v-slot="{ errors }"
              >
                <div class="flex flex-wrap space-right">
                  <selectable-button
                    v-for="(etat, index) in etatsBien"
                    :key="etat + index"
                    v-model="form.informations.etat_bien"
                    :option-name="etat"
                  />
                </div>
                <span
                  v-show="errors[0]"
                  class="mt-1 text-xs text-promy-red-400"
                  >{{ errors[0] }}</span
                >
              </validation-provider>
            </div>
          </div>
        </div>
        <portal-target class="sm:w-full sm:py-2 sm:mt-2" name="next-prev-step">
        </portal-target>
      </div>
    </template>
    <template slot="right-side">
      <Map
        :isActive="isActive"
        v-if="isActive"
        :form="form"
        :cadastre="cadastre"
      />
    </template>
  </LayoutTunnel>
</template>
<script>
import Map from '../Map'

export default {
  components: { Map },
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    form: {
      type: Object,
      required: true,
    },
    cadastre: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      pieces: [
        '1 pièce',
        '2 pièces',
        '3 pièces',
        '4 pièces',
        '5 pièces',
        '6+ pièces',
      ],
      etatsBien: [
        'Refait à neuf',
        'Habitable en l’état',
        'À raffraichir',
        'Travaux importants',
      ],
    }
  },
}
</script>
<style lang="scss" scoped>
.space-right > button {
  @apply mr-2 mb-2;
}
</style>
